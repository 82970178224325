import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import Header from '../components/Header/Header-2';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MzI1") {
      uri
      title
      content
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const Terms = () => {
    const data = useStaticQuery(getdata);
    const common = data.wpgraphql.page;
    const seo = data.wpgraphql.page.seo;
    return (
        <>
            <Layout>
                <SEO title="Law-Firm" description={common.seo.metaDesc} canonical={common.uri} seo={seo}/>
                <Header home={false}/>
                <main>
                  <section class="term-banner-title py-5">
                      <div class="container">
                          <h2 class="term-banner-title text-white">Terms of Use</h2>
                      </div>
                  </section>
                  <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="row">
                                <div className="col-md-12">
                                    <div dangerouslySetInnerHTML={{ __html: common.content }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </main>
            </Layout>
        </>
    )
}
export default Terms